<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {required} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {onCallService} from "@/services/oncall.service";
import {pharmacyService} from "@/services/pharmacy.service";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import { format } from 'date-fns';
import moment from 'moment';

/**
 * Blank page component
 */
export default {
  page: {
    title: "Gardes Pharmaceutiques",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { DatePicker, Multiselect, Layout, PageHeader },
  data() {
    return {
      editing: false,
      onCalls: [],
      pharmacies: [],
      countries: [
        {
          id: 1,
          code: 'tg',
          name: 'Togo'
        },
        {
          id: 2,
          code: 'gh',
          name: 'Ghana'
        }
      ],
      onCall: {},
      title: "Gardes",
      items: [
        {
          text: "Accueil",
          href: "/"
        },
        {
          text: "Gardes",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "begin",
      sortDesc: false,
      fields: [
        { key: "code", label: "Code", sortable: true },
        { key: "begin", label: "Date de début", sortable: true },
        { key: "end", label: "Date de fin", sortable: true },
        { key: "country", label: "Pays", sortable: true },
        { key: "actions", thStyle: { width: "20%" } },
      ],
      submitted: false
    };
  },
  validations: {
    onCall: {
      begin: { required },
      end: { required },
      country: { required },
      pharmacies: { required }
    }
  },
  computed: {
    rows() {
      return this.onCalls.length;
    }
  },
  mounted() {
    this.getPharmacies(); this.getOnCalls();
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const editOnCall = {
          country: this.onCall.country,
          begin: format(this.onCall.begin, 'yyyy-MM-dd h:mm:ss') ,
          end: format(this.onCall.end, 'yyyy-MM-dd h:mm:ss') ,
          pharmacies: this.onCall.pharmacies.map(el => el.id),
          // code: '#' + format(this.onCall.begin, 'ddMM') + '-' + format(this.onCall.end, 'ddMM') + '-' + this.onCall.end.getFullYear(),
        }
        let result = this.onCall.id ? await onCallService.update(this.onCall.id, editOnCall) : await onCallService.create(editOnCall);
        if (result >= 400) {
          Swal.fire("Erreur", "Une erreur s'est produite !", "warning");
        } else {
          Swal.fire(this.onCall.id ? 'Modification' : 'Enregistrement', "Opération reussie !", "success")
          await this.getOnCalls();
          this.clearForm();
        }
      }
    },
    getFormattedDate(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    clearForm() {
      this.submitted = false;
      this.editing = false;
      this.onCall = {};
    },
    async getPharmacies() {
      this.pharmacies = await pharmacyService.get();
    },
    async getOnCalls() {
      this.onCalls = await onCallService.get();
    },
    remove(id) {
      Swal.fire({
        title: "Suppression",
        text: `Vous êtes sûr de supprimer ${this.onCall.code} ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non"
      }).then(async result => {
        if (result.value) {
          let result = await pharmacyService.remove(id);
          if (result.status === 200) await Swal.fire("Suppression", "Période de gardes supprimée avec succès !", "success");
        }
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items">
      <button v-if="!editing" type="button" class="btn btn-success" @click="editing = true">Nouvelle garde</button>
    </PageHeader>

    <div v-if="editing" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Nouvelle garde</h4>
            <p class="card-title-desc">Définir une nouvelle période de garde via le formulaire</p>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-4">
                  <div class="mb-3">
                    <label class="form-label" for="country">Pays</label>
                    <select v-model="onCall.country" id="country" class="form-select"
                            :class="{ 'is-invalid': submitted && $v.onCall.country.$error }">
                      <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                    </select>
                    <div v-if="submitted && $v.onCall.country.$error" class="invalid-feedback">
                      <span v-if="!$v.onCall.country.required">La selection du pays est obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label class="form-label" for="begin">Date de début</label>
                    <date-picker v-model="onCall.begin" format="MM/DD/YYYY" :first-day-of-week="1" lang="fr" placeholder="Selectionner la date de début"
                                 :class="{ 'is-invalid': submitted && $v.onCall.begin.$error }" id="begin"></date-picker>
                    <div v-if="submitted && $v.onCall.begin.$error" class="invalid-feedback">
                      <span v-if="!$v.onCall.begin.required">La date de début est obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label class="form-label" for="end">Date de fin</label>
                    <date-picker v-model="onCall.end" format="MM/DD/YYYY" :first-day-of-week="1" lang="fr" placeholder="Selectionner la date de fin"
                                 :class="{ 'is-invalid': submitted && $v.onCall.end.$error }" id="end"></date-picker>
                    <div v-if="submitted && $v.onCall.end.$error" class="invalid-feedback">
                      <span v-if="!$v.onCall.end.required">La date de fin est obligatoire</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label" for="pharmacies">Pharmacies de garde</label>
                    <multiselect v-model="onCall.pharmacies" :options="pharmacies" :multiple="true" label="name" track-by="id" placeholder="Selectionner les pharmacies"
                                 :close-on-select="false" :clear-on-select="true" :class="{ 'is-invalid': submitted && $v.onCall.pharmacies.$error }" id="pharmacies"></multiselect>
                    <div v-if="submitted && $v.onCall.pharmacies.$error" class="invalid-feedback">
                      <span v-if="!$v.onCall.pharmacies.required">Selectionner les pharmacies qui sont de garde à cette date</span>
                    </div>
                  </div>
                </div>

              </div>
              <button class="btn btn-success me-3" type="submit">Enregistrer</button>
              <button class="btn btn-secondary" type="reset" @click.prevent="clearForm">Annuler</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!editing" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Liste des gardes pharmaceutiques</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Affiche
                    <b-form-select class="form-control form-control-sm form-select form-select-sm ms-1 mx-1" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entrées
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Rechercher:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Rechercher..."
                        class="form-control form-control-sm ml-2 ms-1"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                  :items="onCalls"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >
                <template #cell(begin)="row">
                  {{ getFormattedDate(row.value) }}
                </template>

                <template #cell(end)="row">
                  {{ getFormattedDate(row.value) }}
                </template>

                <template #cell(actions)="row">
                  <b-button size="sm" class="bg-light">
                    Voir
                  </b-button>
                  <b-button size="sm" class="bg-warning mx-2" @click="onCall = row.item; editing = true">
                    Modifier
                  </b-button>
                  <b-button size="sm" class="bg-danger" @click="remove(row.item.id)">
                    Supprimer
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.multiselect__tag {
  background-color: #ececf1 !important;
  color: #000;
}
</style>


// import Cryptr from 'cryptr';
import errorHandler from "@/services/handler/erroHandler";
import axiosInstance from "@/services/manager/manager.service";



export const onCallService = {
    get,
    create,
    update
};
/*let crypt = new Cryptr(process.env.VUE_APP_REMEMBER_CRYPT);*/

async function get(){
    try {
        let rets = await axiosInstance.get(`/oncalls`);
        if (rets) {
            return rets.data;
        }
        return 400;
    } catch (error) {
        return errorHandler(error);
    }
}

async function create(onCall) {
    try {
        let ret = await axiosInstance.post(`/oncalls`, onCall);
        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function update(id, data) {
    try {
        let ret = await axiosInstance.put(`/oncalls/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}




import errorHandler from "@/services/handler/erroHandler";
import axiosInstance from "@/services/manager/manager.service";


export const pharmacyService = {
    get,
    getDrugs,
    create,
    update,
    remove
};

async function get(){
    try {
        let rets = await axiosInstance.get(`/pharmacies`);
        if (rets) {
            return rets.data;
        }
        return 400;
    } catch (error) {
        return errorHandler(error);
    }
}

async function getDrugs(){
    try {
        let rets = await axiosInstance.get(`/medicines`);
        if (rets) {
            return rets.data;
        }
        return 400;
    } catch (error) {
        return errorHandler(error);
    }
}

async function create(pharmacy) {
    try {
        let ret = await axiosInstance.post(`/pharmacies`, pharmacy);
        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function update(id, data) {
    try {
        let ret = await axiosInstance.put(`/pharmacies/${id}`, data);
        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function remove(id) {
    try {
        return await axiosInstance.delete(`/pharmacies/${id}`);
    } catch (error) {
        return errorHandler(error);
    }
}



